import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal, Form as BSForm } from 'react-bootstrap';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { sectionApi } from '@services/sectionApi';
import { departmentApi } from '@services/departmentApi';
import { employeeApi } from '@services/employeeApi';
import { DataContext } from './SectionPage';

function Form(props) {
  const value = props.data;
  const isEdit = props.type === 'UPDATE';

  const { setData } = useContext(DataContext);

  const [departmentOptons, setDepartmentOptons] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');

  const [managerOptons, setManagerOptons] = useState([]);
  const [selectedManager, setSelectedManager] = useState('');

  const [sectionName, setSectionName] = useState();

  const [activeStatus, setActiveStatus] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);

    setSectionName(value?.section_name || '');
    getDepartment(value?.department_id);
    getManager(value?.manager_id);
    setActiveStatus(
      value?.status === 'ENABLE' || value?.status === 'Y' ? true : false
    );

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      id: value.id,
      section_name: sectionName,
      department_id: selectedDepartment.value,
      department_name: selectedDepartment.label,
      manager_id: selectedManager.value,
      employee_gen: selectedManager.employee_gen,
      manager_name: selectedManager.fullname,
      status: activeStatus ? 'Y' : 'N',
    };

    const apiConfig = isEdit
      ? sectionApi.updateByAdmin(payload, signal)
      : sectionApi.createByAdmin(payload, signal);

    try {
      const response = await apiRequest(apiConfig);

      setIsLoading(false);

      if (isEdit) {
        //update datatable
        setData((prevList) => {
          return prevList.map((prev) => {
            if (prev.id === value.id) {
              return {
                ...prev,
                ...payload,
              };
            }
            return prev;
          });
        });
        sendSuccessMessage('Berhasil perbarui data');
      } else {
        payload.id = response.data.data.id;
        setData((prevList) => {
          return [...prevList, payload];
        });

        sendSuccessMessage('Berhasil simpan data');
      }

      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  const getDepartment = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const params = {
      pageSize: 1000,
    };

    try {
      const request = await apiRequest(
        departmentApi.getListByAdmin(params, signal)
      );

      const response = request?.data?.data;
      const options = response.map((item) => {
        return { value: item.id, label: item.department_name };
      });

      const indexOption = options.findIndex(
        (option) => option.value === selectedValue
      );

      setDepartmentOptons(options);

      setSelectedDepartment(options[indexOption]);
    } catch (error) {
      setDepartmentOptons([]);
      setSelectedDepartment('');
    }
  };

  const getManager = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const request = await apiRequest(employeeApi.getListOptions(signal));

      const response = request?.data?.data;
      const options = response.map((item) => {
        return {
          value: item.id,
          label: `${item.fullname} (NIP: ${item.employee_gen})`,
          fullname: item.fullname,
          employee_gen: item.employee_gen,
        };
      });

      const indexOption = options.findIndex(
        (option) => option.value === selectedValue
      );

      setManagerOptons(options);

      setSelectedManager(options[indexOption]);
    } catch (error) {
      setManagerOptons([]);
      setSelectedManager('');
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>{isEdit ? 'Edit ' : 'Tambah '} Jabatan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="inputForm" onSubmit={handleSubmit} className="mt-3">
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Nama Jabatan <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Masukkan nama jabatan"
                value={sectionName}
                onChange={(e) => setSectionName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Departemen <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih departemen"
                options={departmentOptons}
                value={selectedDepartment || ''}
                onChange={(selected) => {
                  setSelectedDepartment(selected);
                }}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Atasan Langsung <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih atasan langsung"
                options={managerOptons}
                value={selectedManager || ''}
                onChange={(selected) => {
                  setSelectedManager(selected);
                }}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">Status</label>
            <div className="col-12 col-sm-8 d-flex align-items-center">
              <BSForm.Check
                type="switch"
                id="active-status"
                checked={activeStatus}
                onChange={(event) => {
                  setActiveStatus(!activeStatus);
                }}
                label={activeStatus ? 'Aktif' : 'Nonaktif'}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="inputForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isEdit ? 'Perbarui ' : 'Simpan'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Form;
