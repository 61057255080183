import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { DataContext } from './ViewTypePerformance';
import { performanceApi } from '@services/performanceApi';

function TypePerformanceForm(props) {
  const [typeName, setTypeName] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);
    setTypeName('');
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      type_name: typeName,
    };

    try {
      await apiRequest(performanceApi.addTypePerformance(payload, signal));

      setIsLoading(false);

      props.reloadData();

      sendSuccessMessage('Berhasil menambahkan Tipe Kewenangan Baru');
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      contentClassName="shadow bg-light"
      centered
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Tambah Tipe Kewenangan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="inputTypeForm" onSubmit={handleSubmit} className="mt-3">
          <div className="row">
            <label className="col-sm-3 col-form-label">
              Nama Tipe <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                placeholder="Nama Tipe Kewenangan"
                value={typeName}
                onChange={(e) => setTypeName(e.target.value)}
                required
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="inputTypeForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isLoading ? 'Menyimpan... ' : 'Simpan'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TypePerformanceForm;
